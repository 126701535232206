<template>
  <div
    v-if="hasConfigBox"
    class="main-wrapper"
    :class="[mainWrapperClass]"
    :style="{ backgroundColor: cor1 }"
  >
    <!-- Navigation -->
    <Navbar
      :logo="require('@/assets/images/logo/logo-light-icon.png')"
      :title="logotitle"
    />

    <!-- Sidebar -->
    <SideBar
      parent=".main-wrapper"
      :sidebar-links="sidebarLinks"
    />

    <!-- Page Container -->
    <div class="main-container-fluid">
      <transition
        name="router-anim"
        enter-active-class="fade-enter-active fade-enter"
        leave-active-class="fade-leave-active fade-enter"
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
/* Components */
import Navbar from '@/layout/full/header/NavbarSurvey.vue';
import SideBar from '@/layout/full/surveysidebar/SideBar.vue';
import sidebarLinks from '@/layout/full/surveysidebar/sidebarlinks.js';

/* Style */
import themeConfig from '@/../themeConfig.js';

export default {
  name: 'DashContainer',
  components: {
    Navbar,
    SideBar
  },
  data: () => ({
    sidebarLinks: sidebarLinks
  }),
  computed: {
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    hasConfigBox() {
      if (Object.keys(this.$store.state.configBox).length === 0) {
        return false;
      } else {
        return true;
      }
    },
    logotitle() {
      return themeConfig.logotitle;
    },
    mainWrapperClass() {
      if (this.sidebarWidth == 'default') {
        return 'main-wrapper-default';
      } else if (this.sidebarWidth == 'mini') {
        return 'main-wrapper-mini';
      } else if (this.sidebarWidth) {
        return 'main-wrapper-full';
      }
      return 'default';
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    }
  }
};
</script>

<style scoped>
.main-container-fluid {
  padding: 0px;
}
</style>
