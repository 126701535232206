export default [
  {
    url: '/dashboards',
    name: 'Dashboards',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Dashboards',
    index: 1,
    child: [
      {
        url: '/dashboards/general-surveys',
        name: 'general-surveys',
        icon: 'mdi mdi mdi-file-check',
        i18n: 'surveys',
        index: 1.1,
      },
      {
        url: '#',
        name: 'iddigital',
        icon: 'mdi mdi mdi-account',
        i18n: 'iddigital',
        index: 1.32,
      }
    ]
  },
  {
    url: '/config',
    name: 'config',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Config',
    index: 1,
    child: [
      {
        url: '/config/surveys',
        name: 'config-surveys',
        icon: 'mdi mdi mdi-adjust',
        i18n: 'surveys',
        index: 1.1,
      }
      /* ,
            {
                url: '/dashboards/id-digital',
                name: "iddigital",
                icon: "mdi mdi mdi-adjust",
                i18n: "iddigital",
                index: 1.32,
            },
            {
                url: '/dashboards/classic-dashboard',
                name: "Classic",
                icon: "mdi mdi mdi-adjust",
                i18n: "Classic",
                index: 1.1,
            },
            {
                url: '/dashboards/analytical-dashboard',
                name: "Analytical",
                icon: "mdi mdi mdi-adjust",
                i18n: "Analytical",
                index: 1.2,
            },
            {
                url: '/dashboards/ecommerce-dashboard',
                name: "Ecommerce",
                icon: "mdi mdi mdi-adjust",
                i18n: "Ecommerce",
                index: 1.3,
            },
            {
                url: '/dashboards/general-dashboard',
                name: "General",
                icon: "mdi mdi mdi-adjust",
                i18n: "General",
                index: 1.4,
            }*/
    ]
  }
]