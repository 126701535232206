<template>
  <header class="gridx">
    <vs-navbar
      v-model="indexActive"
      :color="cor2"
      class="topnavbar"
      text-color="rgba(255,255,255,0.7)"
      active-text-color="rgba(255,255,255,1)"
      :style="{ backgroundColor: cor1 }"
    >
      <div
        class="pt-3 mt-2 pb-2"
        :style="
          isSidebarActive ?
            { position: 'relative', right: '-150px', width: '75%' } :
            { width: '85%' }
        "
      >
        <div
          v-if="checkItemAccess('Fanbox')"
          class="col-sm-12 w-100 px-5 col-md-12 d-flex justify-content-around form-group"
        >
          <div class="d-flex justify-content-center">
            <i
              class="fa fa-id-card text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center"
            >
              <p class="mb-0">
                <span
                  v-if="fanIds == null"
                  class="dot-flashing d-block"
                />
                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ fanIds | formatLocale }}
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >
                {{ fanIdDesc || 'Fan IDs' }}
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <i
              class="fa fa-user text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center"
            >
              <p class="mb-0">
                <span
                  v-if="fanProfile == null"
                  class="dot-flashing d-block"
                />
                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ fanProfile | formatLocale }}
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >
                IDs Únicos
              </span>
            </div>
          </div>

          <div
            class="d-flex justify-content-center"
          >
            <i
              class="fa fa-address-book text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center"
            >
              <p class="mb-0">
                <span
                  v-if="cpfs == null"
                  class="dot-flashing d-block"
                />

                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ cpfs.number | formatLocale }}

                  <small>
                    {{ cpfs.perc | formatLocaleFixed0 }}%
                  </small>
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >
                CPF
              </span>
            </div>
          </div>

          <span class="d-flex justify-content-center">
            <i
              class="fa fa-envelope text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center pt-1"
            >
              <p class="mb-0">
                <span
                  v-if="emailSidebar == null"
                  class="dot-flashing d-block"
                />

                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ emailSidebar.number | formatLocale }}

                  <small>
                    {{ emailSidebar.perc | formatLocaleFixed0 }}%
                  </small>
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal align-self-center"
              >
                E-mail
              </span>
            </div>
          </span>

          <span class="d-flex justify-content-center">
            <i
              class="fa fa-phone-alt text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center pt-1"
            >
              <p class="mb-0">
                <span
                  v-if="telefone == null"
                  class="dot-flashing d-block"
                />

                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ telefone.number | formatLocale }}

                  <small>
                    {{ telefone.perc | formatLocaleFixed0 }}%
                  </small>
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >Telefone</span>
            </div>
          </span>

          <span class="d-flex justify-content-center">
            <i
              class="fas fa-home text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center pt-1"
            >
              <p class="mb-0">
                <span
                  v-if="cep == null"
                  class="dot-flashing d-block"
                />

                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ cep.number | formatLocale }}

                  <small>
                    {{ cep.perc | formatLocaleFixed0 }}%
                  </small>
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >
                CEP
              </span>
            </div>
          </span>

          <span class="d-flex justify-content-center">
            <i
              class="fas fa-book text-white align-self-center"
              style="font-size: 1.2rem"
            />

            <div
              class="iconSidebar text-left ml-2 text-white font-weight-bold align-self-center pt-1"
            >
              <p class="mb-0">
                <span
                  v-if="contatosCompletos == null"
                  class="dot-flashing d-block"
                />

                <span
                  v-else
                  style="font-size: 1.05rem;"
                >
                  {{ contatosCompletos.number | formatLocale }}

                  <small>
                    {{ contatosCompletos.perc | formatLocaleFixed0 }}%
                  </small>
                </span>
              </p>

              <span
                style="font-size:0.93rem;"
                class="font-weight-normal"
              >
                Contatos Full
              </span>
            </div>
          </span>
        </div>
      </div>
    </vs-navbar>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data: () => ({
    indexActive: 0,
    fanIdDesc: null,
    fanIds: null,
    fanProfile: null,
    contatos: 0,
    cpfs: null,
    emailSidebar: null,
    telefone: null,
    survey: 0,
    cep: null,
    cadastrosCompletos: {},
    contatosCompletos: {}
  }),
  computed: {
    boxStat() {
      return this.$store.state.boxstat;
    },
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    isSidebarActive() {
      return this.$store.state.isSidebarActive;
    }
  },
  watch: {
    '$store.state.boxstat'() {
      this.configBar();
    },
    '$store.state.configBox'() {
      this.configBar();
    },
  },
  mounted() {
    this.configBar();
  },
  methods: {
    configBar() {
      this.fanIdDesc = this.boxStat.descFanId;
      this.fanIds = this.boxStat.totalFanIds;
      this.fanProfile = this.boxStat.totalIds;
      this.cadastrosCompletos = this.boxStat.totCadastroCompleto;
      this.contatosCompletos = this.boxStat.totContatoCompleto;
      this.contatos = this.boxStat.totEmail;
      this.cpfs = this.boxStat.totCpf;
      this.emailSidebar = this.boxStat.totEmail;
      this.telefone = this.boxStat.totCel;
      this.cep = this.boxStat.totEndereco;

      if (this.boxStat.surveys) {
        this.survey = this.boxStat.surveys.value;
      } else {
        this.survey = 0;
      }
    },
    checkItemAccess(access) {
      if (!access) return true;

      if (this.$store.state.loginData.accessValues.includes(access)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
.topnavbar {
  color: #fff;
  min-height: 186px;
  position: sticky;
  background-repeat: no-repeat;
  background-size: cover;
}

.vs-sidebar--header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 68px 33px;
  background: transparent;
}

.left-sidebar .vs-sidebar.vs-sidebar-parent {
  top: 0px;
  height: calc(100vh - 0px) !important;
  z-index: 15000;
}

.main-container-fluid {
  padding: 30px 20px 20px;
}

@media (min-width: 576px) {
  .localLogo {
    margin-left: 800px;
  }
}

@media (min-width: 768px) {
  .localLogo {
    margin-left: 690px;
  }
}

@media (min-width: 992px) {
  .localLogo {
    margin-left: 900px;
  }
}

@media (min-width: 1200px) {
  .localLogo {
    margin-left: 1000px;
  }
}

.linha-vertical {
  height: 20px;
  border-left: 1px solid;
}
</style>
